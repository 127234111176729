<template>
  <div>
    <Divider dashed><span class="divider-text">商圈站点汇总</span></Divider>
    <Button class="right" size="small" type="success" :disabled="isDownload" @click="handleDownload">导出商圈信息</Button>
    <p><span class="title">查询关键字</span> {{bussinessQuery.keyword===''?'-':bussinessQuery.keyword}}</p>
    <p><span class="title">适配范围</span>{{bussinessQuery.distance}}米</p>

    <h4 class="workplatform-title m-t-10 m-b-10">站点级别分布</h4>
    <!-- 显示等级分布 -->
    <Row :gutter="8" class="p-b-5">
        <i-col v-for="(item,i) in levelCount" :key="i" span="12" class="p-b-5">
            <div :class="chooseLevel ===item.level?'order-level-active':'order-level'" @click="handleSearchLevel(item)">
                <span>{{item.levelName}}</span>
                <span class="right">{{item.quantity}}</span>
            </div>
        </i-col>
    </Row>

    <h4 class="workplatform-title m-t-10">资源站点分布</h4>
    <template v-for="asset in assetCount">
      <div :key="asset.id" class="p-b-10" v-if="stationCount.filter(x=>x.assetId === asset.id).length > 0">
        <span class="title">{{asset.name}}</span>
        <div v-for="station in stationCount.filter(x=>x.assetId === asset.id)" :key="station.id" class="m-b-2"
            :class="chooseStationId===station.id?'workplatform-radio-button-active':'workplatform-radio-button'"
              @click="changeCheckedStation(station.id)">{{station.name}}</div>
      </div>
    </template>

    <h4 class="workplatform-title m-b-5">商家清单</h4>
    <Row class="table-title">
        <i-col span="12">商家名称</i-col>
        <i-col span="6">细分类型</i-col>
        <i-col span="6" class="text-center">距离位置(米)</i-col>
    </Row>

    <Row v-for="(business,index) in bussinessData" :key="index" class="p-t-2" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
        <i-col span="12">{{business.businessName}}</i-col>
        <i-col span="6">{{business.bigType}}</i-col>
        <i-col span="6" class="text-center">{{business.distance}}</i-col>
    </Row>
    <div class="paging_style">
        <Page size="small" :total="bussinessTotal" :page-size="bussinessQuery.pageSize" :current="bussinessQuery.pageNumber" @on-change="handlePageChange"></Page>
    </div>
  </div>
</template>

<script>
import { getBusinessPage } from '@/api/dmp/business'
import { downloadFileRequest } from '@/utils/download'

export default {
  data () {
    return {
      historyStationCount: [], // 全部点位集合
      assetCount: [],
      stationCount: [],
      levelCount: [],

      searchResultStations: [], // 查询后的站点清单
      chooseStationId: null,
      chooseLevel: null,

      bussinessQuery: {
        distance: 200,
        pageNumber: 1,
        pageSize: 10,
        stationId: 0,
        keyword: ''
      },
      bussinessTotal: 0,
      bussinessData: [],

      isOnlyUpdateMap: false
    }
  },
  methods: {
    initPageData () {
      this.historyStationCount = this.stations
      this.bussinessQuery.distance = this.searchCondition.arounddistance
      this.bussinessQuery.keyword = this.searchCondition.aroundkeyword
      this.chooseLevel = undefined
      this.handleSearchLevel()
      this.groupByLevelAndAsset()
    },
    groupByLevelAndAsset () {
      const alllevels = []
      const allAssets = []
      let currentLevel
      let currentAsset

      this.historyStationCount.forEach(element => {
        currentLevel = alllevels.find(x => x.level === element.level)
        currentAsset = allAssets.find(x => x.id === element.assetId)
        // 获取站点级别分组
        if (currentLevel) {
          currentLevel.quantity++
        } else {
          alllevels.push({
            level: element.level, levelName: element.levelName, quantity: 1
          })
        }
        // 获取线路分组
        if (!currentAsset) {
          allAssets.push({
            id: element.assetId, name: element.assetName
          })
        }
      })

      this.levelCount = alllevels.sort((a, b) => (a.level - b.level))
      this.assetCount = allAssets.sort((a, b) => (a.assetId - b.assetId))
    },
    handleSearchLevel (param) {
      if (!param || this.chooseLevel === param.level) {
        this.chooseLevel = undefined
        this.stationCount = this.historyStationCount
      } else {
        this.chooseLevel = param.level
        this.stationCount = this.historyStationCount.filter(x => x.level === param.level)
      }
    },
    changeCheckedStation (stationId) {
      if (this.chooseStationId === stationId) {
        this.resetPage()
      } else {
        this.chooseStationId = stationId
        this.bussinessQuery.pageNumber = 1
        this.bussinessQuery.stationId = stationId
        this.flickerMap(stationId)
        this.searchBussinessPage()
      }
    },
    searchBussinessPage () {
      getBusinessPage(this.bussinessQuery).then(res => {
        this.bussinessTotal = res.totalRow
        this.bussinessData = res.list
      })
    },
    handlePageChange (page) {
      this.bussinessQuery.pageNumber = page
      this.searchBussinessPage()
    },
    handleDownload () {
      const parms = {
        keyword: this.bussinessQuery.keyword,
        distance: this.bussinessQuery.distance,
        stationId: this.chooseStationId
      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-dm/v1/business/exportBusinessInfo', parms, '点位周边商圈信息.xlsx')
    },
    restoreMap () { // 还原地图点位标识
      this.isOnlyUpdateMap = true

      this.$store.commit('set_stock_format_map_type', 'resource')
      this.$store.commit('set_stock_format_stations', this.stations)
      this.$store.commit('set_stock_format_update', new Date())
    },
    flickerMap (stationId) { // 地图闪光标识
      this.isOnlyUpdateMap = true

      this.$store.commit('set_stock_format_map_type', 'flicker')
      this.$store.commit('set_stock_format_stations', [stationId])
      this.$store.commit('set_stock_format_update', new Date())
    },
    resetPage () {
      this.chooseStationId = null
      this.bussinessQuery.pageNumber = 1
      this.bussinessTotal = 0
      this.bussinessData = []
      this.restoreMap()
    }
  },
  computed: {
    isDownload () {
      return this.bussinessData.length < 1
    },
    beginUpdate () {
      return this.$store.state.stockFormat.beginUpdate
    },
    searchCondition () {
      return this.$store.state.stockFormat.searchCondition
    },
    stations () {
      return this.$store.state.stockFormat.stations
    },
    searchRange () {
      return this.$store.state.stockFormat.searchRange
    }
  },
  watch: {
    beginUpdate (val) {
      if (this.isOnlyUpdateMap) {
        this.isOnlyUpdateMap = false
      } else {
        this.initPageData()
      }
    },
    searchRange (val) {
      this.bussinessQuery.pageNumber = 1
      this.bussinessQuery.distance = val

      // 选中站点
      if (this.chooseStationId !== null) {
        this.searchBussinessPage()
      }
    }
  }
}
</script>
